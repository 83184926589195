
const verifyDSDafaultState = {
    fileForDS: null,
    fileForDSName: "",
    fileForDSDescription: "",
    fileForDSSelected: false,
    dsData: null,
    previsionDsFileSelected: false,
    previsionDsFileName: "",
    previsionDsFile: null,
    fileList: [],
    filesWithDS: [],
    qrList: [],
    verifyResults: null,
    verifyResultsForQR: null,
    active: false,
    fileForPrivateSignSelected: false,
    fileForPrivateSign: null,
    fileForPrivateSignName: "",
    pngChecked: true,
    qrChecked: false,
    fileForPrivateSignPng: null,
    fileForPrivateSignPngName: "",
    fileForPrivateSignPngSelected: false,
    filesWithDSForPrivateSign: null
}

const createDSInputReducer = (state = verifyDSDafaultState, { type, payload }) => {

    switch (type) {
    	case "SET_INPUT_FILE_FOR_DS": {
		    return {
		        ...state,
		        fileForDS: payload,
		        fileForDSName: payload.name,
                fileForDSDescription: payload.name,
		        fileForDSSelected: true,
		    }
		}

        case "SET_FILE_FOR_PRIVATE_SIGN": {
            return {
                ...state,
                fileForPrivateSign: payload,
                fileForPrivateSignName: payload.name,
                fileForPrivateSignSelected: true,
            }
        }

        case "SET_FILE_FOR_PRIVATE_SIGN_PNG": {
            return {
                ...state,
                fileForPrivateSignPng: payload,
                fileForPrivateSignPngName: payload.name,
                fileForPrivateSignPngSelected: true,
            }
        }

        case "CLEAR_FILE_FOR_PRIVATE_SIGN": {
            return {
                ...state,
                fileForPrivateSign: null,
                fileForPrivateSignName: "",
                fileForPrivateSignSelected: false,
            }
        }

        case "CLEAR_FILE_FOR_PRIVATE_SIGN_PNG": {
            return {
                ...state,
                fileForPrivateSignPng: null,
                fileForPrivateSignPngName: "",
                fileForPrivateSignPngSelected: false,
            }
        }

        case "SET_VERIFY_RESULTS_FOR_ADD_TO_EXIST": {
            return {
                ...state,
                verifyResults: payload
            }
        }

        case "SET_QR_VERIFY_RESULTS_FOR_ADD_TO_EXIST": {
            return {
                ...state,
                verifyResultsForQR: payload
            }
        }

         case "CLEAR_VERIFY_RESULTS_FOR_ADD_TO_EXIST": {
            return {
                ...state,
                verifyResults: null
            }
        }

        case "CLEAR_QR_VERIFY_RESULTS_FOR_ADD_TO_EXIST": {
            return {
                ...state,
                verifyResultsForQR: null
            }
        }

        case "SET_FILES_WITH_DS": {
            return {
                ...state,
                filesWithDS: payload
            }
        }

        case "SET_FILES_WITH_DS_FOR_PRIVATE_SIGN": {
            return {
                ...state,
                filesWithDSForPrivateSign: payload
            }
        }

        case "SET_FILES_FOR_DS": {
            return {
                ...state,
                fileList: payload
            }
        }

		case "CLEAR_INPUT_FILE_FOR_DS": {
		    return {
		        ...state,
		        fileForDS: null,
		        fileForDSName: "",
		        fileForDSSelected: false,
		    }
		}

        case "CLEAR_FILE_LIST": {
            return {
                ...state,
                fileList: []
            }
        }

        case "CLEAR_FILE_LIST_WITH_DS": {
            return {
                ...state,
                filesWithDS: []
            }
        }

        case "SET_PREVISION_DS_DATA": {
            return {
                ...state,
                previsionDsFile: payload,
                previsionDsFileName: payload.name,
                previsionDsFileSelected: true,
            }
        }

        case "CLEAR_PREVISION_DS_DATA": {
            return {
                ...state,
                previsionDsFile: null,
                previsionDsFileName: "",
                previsionDsFileSelected: false,
            }
        }

        case 'SET_DS_INPUT_DESCRIPTION': {
            return {
                ...state,
                fileForDSDescription: payload,
            }
        }

        case 'SET_DS_DATA': {
            return {
                ...state,
                dsData: payload,
            }
        }

        case 'CLEAR_DS_DATA': {
            return {
                ...state,
                dsData: null,
            }
        }

        case 'SET_DS_ACTIVE': {
            return {
                ...state,
                active: payload,
            }
        }

        case 'SET_QR_LIST': {
            return {
                ...state,
                qrList: payload,
            }
        }

        case 'SET_PNG_CHECKED': {
            return {
                ...state,
                pngChecked: payload,
            }
        }

        case 'SET_QR_CHECKED': {
            return {
                ...state,
                qrChecked: payload,
            }
        }

        case 'CLEAR_QR_LIST': {
            return {
                ...state,
                qrList: [],
            }
        }

        case 'CLEAR_DS_INPUT_DESCRIPTION': {
            return {
                ...state,
                fileForDSDescription: "",
            }
        }

        default:
            return state;
    }

}

export default createDSInputReducer